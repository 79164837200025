import extend from 'deep-extend';
import Cookies from 'js-cookie';

export default class {

    constructor(options, statemanager) {
        const defaults = {
            accordionAnimateSpeed: 300,
            btnClose: '.btn-close'
        };

        this.options = extend({}, defaults, options);
        this.$elem = document.querySelector('#notification-banner');

        if (!this.$elem){
            return;
        }

        /*
         Define nodes / elements
         */
        this.$btnClose = this.$elem.querySelector(this.options.btnClose);
        this.$body = document.body;

        console.log(this.$body);
        let CookieCheck = Cookies.get('EW.GlobalAlertClosed');

        if(CookieCheck === undefined){
            this.showBanner();
        }

        this.$btnClose.addEventListener('click', ()=>{
            this.closeBanner();
        });
    }

    saveCookie(value){
        Cookies.set('EW.GlobalAlertClosed', value, { expires: 1 });
    }

    showBanner(){
        this.$elem.classList.add('active');
        this.$body.classList.add('notification-active');
    }

    closeBanner(){
        this.saveCookie(true);
        this.$elem.classList.remove('active');
        this.$body.classList.remove('notification-active');
    }
}
