import extend from 'deep-extend';
import axios from 'axios';

export default class {

    constructor(elem, options) {
        const defaults = {
            recaptchaSiteKey: '',
            apiEndpoint: null
        };
        this.options = extend({}, defaults, options);
        this.$elem = elem;
        this.$form = elem.querySelector("form");
        this.$errorMessage = elem.querySelector(".error-msg");
        this.bindEvents();
    }

    updateButtonState(pending) {
        let button;
        if(pending) {
            button = this.$form.querySelector('[name="submit"]');
            button.setAttribute('disabled', 'disabled');
            button.setAttribute('value', "...");
        }
        else {
            button = this.$form.querySelector('[name="submit"]');
            button.removeAttribute('disabled');
            button.setAttribute('value', "Submit");
        }
    }

    updateErrorState(msg) {
        if(msg !== '') {
            this.$errorMessage.innerHTML = msg;
            this.$errorMessage.classList.add('active');
        }
        else {
            this.$errorMessage.innerHTML = '';
            this.$errorMessage.classList.remove('active');
        }
    }

    bindEvents(){
        this.$form.addEventListener('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();

            this.updateButtonState(true);
            this.updateErrorState('');
            
            let formData = {
                referenceNumber: this.$form.querySelector('[name="ReferenceNumber"]').value,
                amount: this.$form.querySelector('[name="Amount"]').value
            };
            if(typeof grecaptcha != 'undefined') {
                grecaptcha.ready(() => {
                    grecaptcha.execute(this.options.recaptchaSiteKey, {action: 'submit'}).then((token) => {
                        formData.recaptchaToken = token;
                        this.signFields(formData);
                    });
                });
                    
            }
            
        });
    }
    
    signFields(data) {
        axios.post(this.options.apiEndpoint, data)
            .then((response) => {
                if(response.data.status === true) {
                    this.postToPaymentGateway(response.data);    
                }
                else {
                    this.updateButtonState(false);
                    this.updateErrorState('The reCAPTCHA was not validated for this form.');    
                }
            })
            .catch((error) => {
                this.updateButtonState(false);
                this.updateErrorState('An error occurred submitting this form.');
            });
    }
    
    postToPaymentGateway(data) {
        let form = document.createElement('form');
        form.setAttribute("style", "visibility: none; height: 0;");
        form.setAttribute("method", "POST");
        form.setAttribute("target", "payment_form");
        form.setAttribute("onsubmit", "window.open('about:blank','payment_form','width=768,height=800');");
        form.setAttribute("action", data.endpoint);

        for (let key in data.fields) {
            if (data.fields.hasOwnProperty(key)) {
                let field = document.createElement('input');
                field.setAttribute('type', 'hidden');
                field.setAttribute('name', key);
                field.setAttribute('value', data.fields[key]);
                form.appendChild(field);
            }
        }
        let submit = document.createElement('input');
        submit.setAttribute('type', 'submit');
        submit.setAttribute('name', 'submit');
        submit.setAttribute('value', 'Submit');
        form.appendChild(submit);
        document.body.appendChild(form);

        this.$form.querySelector('[name="ReferenceNumber"]').value = '';
        this.$form.querySelector('[name="Amount"]').value = '';
        
        submit.click();
        form.remove();
        
        this.updateButtonState();
        
        // $('input[type="submit"]', form).click();
        // form.remove();
    }
}

